<template>
   <div id="box">
      <h1>躁动行为调查问卷</h1>
      <p>请根据您最近一周以来最切合的感受进行选择。</p>
      <h4>1.击打（包括自己）</h4>
      <el-radio-group v-model="radio">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>2.踢</h4>
      <el-radio-group v-model="radio1">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <br />
      <h4>3.抓住人</h4>
      <el-radio-group v-model="radio2">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <br />
      <h4>4.推（人或物）</h4>
      <el-radio-group v-model="radio3">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>5.扔东西</h4>
      <el-radio-group v-model="radio4">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>6.咬</h4>
      <el-radio-group v-model="radio5">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>7.抓</h4>
      <el-radio-group v-model="radio6">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>8.随地吐痰</h4>
      <el-radio-group v-model="radio7">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>9.伤害自己或他人</h4>
      <el-radio-group v-model="radio8">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>10.撕毁东西或搞破坏</h4>
      <el-radio-group v-model="radio9">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>11.进行身体上的性骚扰</h4>
      <el-radio-group v-model="radio10">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>12.踱步，漫无目的的徘徊</h4>
      <el-radio-group v-model="radio11">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>13.不得体的穿着或脱衣</h4>
      <el-radio-group v-model="radio12">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>14.试图去其他地方</h4>
      <el-radio-group v-model="radio13">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>15.故意跌倒</h4>
      <el-radio-group v-model="radio14">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>16.吃/喝不合适的食物</h4>
      <el-radio-group v-model="radio15">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>17.事情处理不当</h4>
      <el-radio-group v-model="radio16">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>18.藏东西</h4>
      <el-radio-group v-model="radio17">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>19.囤东西</h4>
      <el-radio-group v-model="radio18">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>20.重复某些行为</h4>
      <el-radio-group v-model="radio19">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>21.略显不安</h4>
      <el-radio-group v-model="radio20">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>22.尖叫</h4>
      <el-radio-group v-model="radio21">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>23.进行口头上的性暗示</h4>
      <el-radio-group v-model="radio22">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>24.咒骂或口头攻击</h4>
      <el-radio-group v-model="radio23">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>25.重复一些话和问题</h4>
      <el-radio-group v-model="radio24">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>26.发出奇怪的声音</h4>
      <el-radio-group v-model="radio25">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>27.抱怨</h4>
      <el-radio-group v-model="radio26">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>28.消极和抗拒</h4>
      <el-radio-group v-model="radio27">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group>
      <h4>29.提出无理要求</h4>
      <el-radio-group v-model="radio28">
         <el-radio label="0">A：无</el-radio><br />
         <el-radio label="1">B：每周1次或更少 </el-radio><br />
         <el-radio label="2">C：每周1-2次 </el-radio><br />
         <el-radio label="3">D：每周几次 </el-radio><br />
         <el-radio label="4">E：每天1-2次 </el-radio><br />
         <el-radio label="5">F：每天几次 </el-radio><br />
         <el-radio label="6">G：每小时几次 </el-radio><br />
      </el-radio-group><br>
      <el-button type="success"
                 @click="ExamSolutionADD"
                 :disabled="isdisabled">提交</el-button>
   </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
   mounted () {
      this.get();
   },
   updated () {
      // console.log("总分：", this.total);
      // console.log("说明", this.explain);
   },
   methods: {
      async ExamSolutionADD () {
         const data = await axios({
            url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=躁动行为量表CMAI&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=/&Review=暂无建议&Section=/&Doctor=/`,
            method: "post",
            data,
         });
         if (data.data.data == true) {
            this.isShow = !this.isShow;
            Message.success("提交成功");
            this.$router.push("/Assessment");
         }
      },
      //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
      get () {
         const patientN = localStorage.getItem("patientN");
         const patientI = localStorage.getItem("patientI");
         this.name = JSON.parse(patientN);
         this.cid = JSON.parse(patientI);
      },
   },
   computed: {
      isdisabled () {
         if (
            this.radio == "" ||
            this.radio1 == "" ||
            this.radio2 == "" ||
            this.radio3 == "" ||
            this.radio4 == "" ||
            this.radio5 == "" ||
            this.radio6 == "" ||
            this.radio7 == "" ||
            this.radio8 == "" ||
            this.radio9 == "" ||
            this.radio10 == "" ||
            this.radio11 == "" ||
            this.radio12 == "" ||
            this.radio13 == "" ||
            this.radio14 == "" ||
            this.radio15 == "" ||
            this.radio16 == "" ||
            this.radio17 == "" ||
            this.radio18 == "" ||
            this.radio19 == "" ||
            this.radio20 == "" ||
            this.radio21 == "" ||
            this.radio22 == "" ||
            this.radio23 == "" ||
            this.radio24 == "" ||
            this.radio25 == "" ||
            this.radio26 == "" ||
            this.radio27 == "" ||
            this.radio28 == ""
         ) {
            return true;
         }
         if (
            this.radio != "" ||
            this.radio1 != "" ||
            this.radio2 != "" ||
            this.radio3 != "" ||
            this.radio4 != "" ||
            this.radio5 != "" ||
            this.radio6 != "" ||
            this.radio7 != "" ||
            this.radio8 != "" ||
            this.radio9 != "" ||
            this.radio10 != "" ||
            this.radio11 != "" ||
            this.radio12 != "" ||
            this.radio13 != "" ||
            this.radio14 != "" ||
            this.radio15 != "" ||
            this.radio16 != "" ||
            this.radio17 != "" ||
            this.radio18 != "" ||
            this.radio19 != "" ||
            this.radio20 != "" ||
            this.radio21 != "" ||
            this.radio22 != "" ||
            this.radio23 != "" ||
            this.radio24 != "" ||
            this.radio25 != "" ||
            this.radio26 != "" ||
            this.radio27 != "" ||
            this.radio28 != ""
         ) {
            return false;
         }
      },
      total () {
         return (
            this.radio * 1 +
            this.radio1 * 1 +
            this.radio2 * 1 +
            this.radio3 * 1 +
            this.radio4 * 1 +
            this.radio5 * 1 +
            this.radio6 * 1 +
            this.radio7 * 1 +
            this.radio8 * 1 +
            this.radio9 * 1 +
            this.radio10 * 1 +
            this.radio11 * 1 +
            this.radio12 * 1 +
            this.radio13 * 1 +
            this.radio14 * 1 +
            this.radio15 * 1 +
            this.radio16 * 1 +
            this.radio17 * 1 +
            this.radio18 * 1 +
            this.radio19 * 1 +
            this.radio20 * 1 +
            this.radio21 * 1 +
            this.radio22 * 1 +
            this.radio23 * 1 +
            this.radio24 * 1 +
            this.radio25 * 1 +
            this.radio26 * 1 +
            this.radio27 * 1 +
            this.radio28 * 1
         );
      },
   },
   data () {
      return {
         ETID: "E11",
         isShow: false,
         name: "",
         cid: "",
         radio: "",
         radio1: "",
         radio2: "",
         radio3: "",
         radio4: "",
         radio5: "",
         radio6: "",
         radio7: "",
         radio8: "",
         radio9: "",
         radio10: "",
         radio11: "",
         radio12: "",
         radio13: "",
         radio14: "",
         radio15: "",
         radio16: "",
         radio17: "",
         radio18: "",
         radio19: "",
         radio20: "",
         radio21: "",
         radio22: "",
         radio23: "",
         radio24: "",
         radio25: "",
         radio26: "",
         radio27: "",
         radio28: "",
      };
   },
};
</script>

<style lang="scss" scoped>
// @import "./style.scss";
.el-radio {
   margin: 10px;
}
body {
   background: rgb(220, 245, 179);
}
#box {
   width: 100%;
   margin: auto;
   text-align: left;
   margin-left: 30px;
   // background: rgb(206, 245, 175);
}
.label_color {
   color: rgb(202, 44, 207);
}
h1 {
   text-align: center;
}

h4 {
   color: rgb(140, 102, 245);
}
</style>
